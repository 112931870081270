import {useConnectionStore} from "~/stores/connection";
import type {HandlerSpecification, HandlerSummary} from "~/types/spaces.types";


export const createEnvironment = async (workspaceId: string, name: string) => {
    return useConnectionStore().execute('environment/create', {
        workspaceId, name,
    })
}

export const deleteEnvironment = async (workspaceId: string, environmentId: string) => {
    return useConnectionStore().execute('environment/delete', {
        workspaceId,
        environmentId
    })
}

export const updateEnvironmentName = async (workspaceId: string, environmentId: string, name: string) => {
    return useConnectionStore().execute('environment/name/update', {
        workspaceId,
        environmentId,
        name
    })
}

export const activateEnvironmentCluster = async (workspaceId: string, environmentId: string) => {
    return useConnectionStore().execute('environment/activate/cluster', {
        workspaceId,
        environmentId
    })
}


export const getEnvironmentHistory = async (workspaceId: string, environmentId: string, page: number, pageSize: number) => {
    return useConnectionStore().query('environment/history', {
        workspaceId,
        environmentId,
        page,
        pageSize,
    })
}

export const addWorkspaceUser = async (workspaceId: string, email: string, role: string) => {
    return useConnectionStore().execute('workspace/user/add', {
        workspaceId, email, role
    })
}

export const changeWorkspaceUserRole = async (workspaceId: string, email: string, role: string) => {
    return useConnectionStore().execute('workspace/user/change-role', {
        workspaceId, email, role
    })

}

export const removeWorkspaceUser = async (workspaceId: string, email: string) => {
    return useConnectionStore().execute('workspace/user/delete', {
        workspaceId, email
    })
}

export const transferWorkspaceOwnership = async (workspaceId: string, newOwnerEmail: string) => {
    return useConnectionStore().execute('workspace/owner/transfer', {
        workspaceId, newOwnerEmail
    })
}

export const claimWorkspaceOwnership = async (workspaceId: string, ownerEmail: string) => {
    return useConnectionStore().execute('workspace/owner/claim', {
        workspaceId, ownerEmail
    })
}

export const attachServerFeatures = async (workspaceId: string, environmentId: string, serverFeaturesId: string) => {
    return useConnectionStore().execute('environment/features/attach', {
        workspaceId,
        environmentId,
        serverFeaturesId
    })
}

export const detachServerFeatures = async (workspaceId: string, environmentId: string, serverFeaturesId: string) => {
    return useConnectionStore().execute('environment/features/detach', {
        workspaceId,
        environmentId,
        serverFeaturesId
    })
}

export const getWorkspaceHandlerSummary = async (workspaceId: string) => {
    return useConnectionStore().query<HandlerSummary>('workspace/handler/summary', {
        workspaceId
    })
}
export const getWorkspaceHandlerDetail = async (workspaceId: string) => {
    return useConnectionStore().query<HandlerSpecification>('workspace/handler/detail', {
        workspaceId
    })
}